@defer () {
<mat-sidenav-container>
  <!-- Sidenav -->
  <mat-sidenav #sidenav mode="side" [(opened)]="isSidenavOpened" [fixedInViewport]="true" [style.width]="sidenavWidth"
    [class.sidenavClose]="!sidenavWidthIsMax">
    <div class="sidenav-content" fxLayout="column" fxLayoutAlign="space-between center">
      <!-------------------- Nav Bar  -------------------->
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="20px">
        <!-- Logo -->
        <img src="assets/logo/{{ getLogoImg() }}" alt="logo" width="50%" class="logo" />

        <!--/ Logo -->
        <!-- Nav Links -->
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" class="navbar">
          <div fxLayout="column" fxLayoutAlign="start start" *ngFor="let section of sidenavLinks" class="nav-link-block"
            fxLayoutGap="0px">
            <ng-container *ngIf="readCaslPermissions(section.requiredPermissions ?? [])">
              <div class="nav-link-header" (click)="foldSection(section)">
                <div fxLayout="row" fxLayoutAlign="start center">
                  {{ section.sectionName }}
                  <svg-icon class="section-icon" [src]="'assets/icons/arrow-right-mini-light.svg'" fxLayout="row"
                    fxLayoutAlign="center center" style="width: 16px;"
                    [@rotatedState]='section.rotatedState'></svg-icon>
                </div>
              </div>
              @if(!section.folded){
              <div fxLayout="column" fxLayoutAlign="start strech" fxLayoutGap="0px"
                *ngFor="let link of section.sectionLinks" class="nav-section-links mat-primary-color">
                <div class="nav-link" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0px"
                  (click)="link.onClick ? link.onClick() : null" [routerLink]="
                    link.disabled ? null : link.onClick ? null : link.pathFunc ? link.pathFunc() : link.path
                  " [routerLinkActive]="['nav-link-active']" [ngClass]="{
                    disabled: link.disabled
                  }" [routerLinkActiveOptions]="{ exact: false }"
                  *ngIf="readCaslPermissions(link.requiredPermissions ?? [])" [matTooltip]="link.tooltip ?? ''"
                  matTooltipPosition="right">
                  <svg-icon [src]="'assets/icons/' + link.icon + '.svg'" fxLayout="row" fxLayoutAlign="center center"
                    [svgStyle]="{ 'height.px': 24 }"></svg-icon>
                  <span *ngIf="sidenavWidth == '14em'">{{ link.name }}</span>
                </div>

                <!-- Child Menu -->
                <ng-container *ngIf="link.childMenu">
                  <div *ngFor="let child of link.childMenu" class="nav-link child-nav-link" fxLayout="row"
                    fxLayoutAlign="start center" fxLayoutGap="0px" [routerLink]="child.path"
                    [routerLinkActive]="['nav-link-active']" [routerLinkActiveOptions]="{ exact: false }">
                    <svg-icon [src]="'assets/icons/' + child.icon + '.svg'" fxLayout="row" fxLayoutAlign="center center"
                      [svgStyle]="{ 'height.px': 20 }"></svg-icon>
                    <span *ngIf="sidenavWidth == '14em'">{{ child.name }}</span>
                  </div>
                </ng-container>
                <!-- / Child Menu -->
              </div>
              }
            </ng-container>
          </div>
        </div>
        <!-- / Nav Links -->
      </div>
      <!-------------------- / Nav Bar  -------------------->

      <!-------------------- Actions Buttons  -------------------->
      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" style="margin-bottom: 5px">

        <button mat-stroked-button color="primary" *ngIf="sidenavWidthIsMax" (click)="signOut()">
          <div fxLayout="row" class="nav-link" fxLayoutAlign="start center" fxLayoutGap="10px" (click)="signOut()">
            <mat-icon>logout</mat-icon>
            <span>Se déconnecter</span>
          </div>
        </button>

      </div>
      <!-------------------- / Actions Buttons -------------------->
    </div>
  </mat-sidenav>

  <!-- Content -->
  <mat-sidenav-content [ngStyle]="{ marginLeft: sidenavWidth }">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <!-- ToolBar -->

      <div class="mat-toolbar-container" [ngStyle]="{ paddingLeft: sidenavWidth }">
        <mat-toolbar class="search-toolbar" fxLayoutAlign="space-between center">
          <!-- SearchBar -->
          <app-searchbar style="margin-left: -5px"></app-searchbar>
          <!-- / SearchBar -->

          @if(environment !== 'production') {
          <mat-chip color="primary" highlighted>Environnement : {{getEnvLabel()}}</mat-chip>
          }

          @if(user$ | async) {

          <!-- Site -->
          @if ((user$ | async)?.sites && selectedSite != null && selectedSite !=
          undefined) {

          <mat-form-field appearance="fill" class="select-current-site">
            <mat-select [(value)]="selectedSite" (selectionChange)="updateActiveSite($event)"
              [compareWith]="compareObjects">
              <mat-select-trigger>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="mat-toolbar-compagny">
                  <svg-icon class="icon-chip" [src]="'assets/icons/building-hospital-bulk.svg'" fxLayout="row"
                    fxLayoutAlign="center center"></svg-icon>

                  <span> {{ selectedSite?.name }}</span>
                </div>
              </mat-select-trigger>
              @for (site of (user$ | async)?.sites; track site) {

              <mat-option [value]="site">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="mat-toolbar-compagny">
                  <svg-icon class="icon-chip" [src]="'assets/icons/building-hospital-bulk.svg'" fxLayout="row"
                    fxLayoutAlign="center center"></svg-icon>

                  <span> {{ site.name }}</span>
                </div>
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
          }
          <!-- / Site -->

          <mat-divider [vertical]="true" style="height: 30px"></mat-divider>

          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <a [routerLink]="['/profile']" [queryParams]="{ tab: 1 }">
              <svg-icon class="icon-chip" [src]="'assets/icons/setting-light.svg'" fxLayout="row"
                fxLayoutAlign="center center"></svg-icon>
            </a>
          </div>

          <mat-divider [vertical]="true" style="height: 30px"></mat-divider>

          @if ((user$ | async)?.avatar) {
          <img class="user-circle" src="{{ (user$ | async)?.avatar }}" alt="image" crossorigin="anonymous"
            [routerLink]="'/profile'" style="margin-left: -5px" />
          } @else {
          <img class="user-circle" src="assets/icons/user-bold.svg" alt="image" [routerLink]="'/profile'"
            style="margin-left: -5px" />
          } }
        </mat-toolbar>
      </div>
    </div>

    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
}
