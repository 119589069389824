import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@shared/pages/page-not-found/page-not-found.component';
import { authGuard } from './core/guards/auth.guard';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layouts/content-layout/content-layout.component';
import { PageNotAuthorizedComponent } from './shared/pages/page-not-authorized/page-not-authorized.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'profile',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@modules/profile-and-settings/profile-and-settings.module').then(
        (m) => m.ProfileAndSettingsModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'catalog',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@modules/catalog/catalog.module').then((m) => m.CatalogModule),
    canActivate: [authGuard],
  },
  {
    path: 'verification-label',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@modules/verification_label/verification.label.module').then(
        (m) => m.VerificationLabelModules
      ),
    canActivate: [authGuard],
  },
  {
    path: 'sites',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@modules/site-management/site-management.module').then(
        (m) => m.SiteManagementModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'clients',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import(
        '@modules/client-administration/client-administration.module'
      ).then((m) => m.ClientAdministrationModule),
    canActivate: [authGuard],
  },
  {
    path: 'administration',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@modules/app-administration/app-administration.module').then(
        (m) => m.AppAdministrationModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'documentation',
    component: ContentLayoutComponent,
    loadChildren: () =>
      import('@modules/documentation/documentation.module').then(
        (m) => m.DocumentationModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'not-authorized',
    component: PageNotAuthorizedComponent,
  },
  {
    path: '',
    redirectTo: `/auth`,
    pathMatch: 'full',
  },

  //Wild Card Route for 404 request
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
