<!-- <ng-container>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="dialog-title">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

      <svg-icon [src]="'assets/icon/info-light.svg'" fxLayout="row" fxLayoutAlign="center center"
        class="mat-primary-color" [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"></svg-icon>

      <h3 class="dialog-name">
        Informations - {{ data.title}}
      </h3>
    </div>
    <button mat-icon-button [mat-dialog-close]="true">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

      {{data.content}}

    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="dialog-actions">
      <button mat-flat-button color="warn" mat-dialog-close="">Ok</button>
    </div>
  </mat-dialog-content>
</ng-container> -->

<mat-dialog-content fxLayout="column" fxLayoutGap="30px">
  <h2 class="title-mat-card">Information - {{data.title}}</h2>

  <div fxLayout="column" fxLayoutAlign="center center" [innerHTML]="data.content | safeHtml">
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="dialog-actions">
    <button mat-flat-button color="primary" mat-dialog-close="">Ok</button>
  </div>
</mat-dialog-content>
